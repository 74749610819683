import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Equipment from '../views/Equipment.vue'
import Surroundings from '../views/Surroundings.vue'
import Rules from '../views/Rules.vue'
import Directions from '../views/Directions.vue'
import Price from '../views/Price.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Info',
    component: Home
  },
  {
    path: '/surroundings',
    name: 'Umgebung',
    component: Surroundings
  },
  {
    path: '/price',
    name: 'Preis',
    component: Price
  },
  {
    path: '/equipment',
    name: 'Ausstattung',
    component: Equipment
  },
  {
    path: '/directions',
    name: 'Anfahrt',
    component: Directions
  },
  {
    path: '/rules',
    name: 'Regeln',
    component: Rules
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
